.manual-entry-options {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    max-height: 150px;
    margin: 0px;
    padding: 0px;
    z-index: 25001;
    transform: translateZ(25001px);


    button {
        overflow: hidden;
        width: 100%;
        padding: 0.75em;
        list-style-type: none;
        text-align: left;
        font-size: 1rem;
        line-height: 1.5rem;
        max-height: 3rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: none;

        &.multiline {
            max-height: 4rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-word;
            overflow: hidden;
            hyphens: auto;
            -webkit-hyphens: auto;
            white-space: initial;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.02);
        }
    }

    button:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.01);
    }

    .manual-entry-option-txt {
        // @include txt-body('sm');
        @include txt-emphasis('medium');
    }

}

.manual-entry-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 25000;
    transform: translateZ(25000px);
}
