// Component variables
$card-border-radius: 12px;
$hover-bg: rgba(var(--hover-background), 0.1);


// Starter styles for a card
@mixin card {
  padding: 32px;
  border-radius: $card-border-radius;
  box-shadow: $smooth-box-shadow;
  transition: all 0.2s ease;
}

// Sectional divider
@mixin divider {
  border: 0;
  margin: 4px 0px;
  height: 1px;
  background-color: var(--ion-text-color);
  opacity: 0.38;
}

// // Icons with links
// @mixin a {
//   // @include ion-icon{
//     color: $foggy-gray-hex;

//     &:hover {
//       color: $sky-blue-light-hex;
//     }
//   // }

// }

@mixin console-list-item {
  $border-idle: 1px solid rgba(var(--ion-text-color-rgb), 0.3);

  @include flex-row;
  border: $border-idle;
  width: 100%;
  height: 100%;
  max-height: 112px;
  border-radius: $card-border-radius;
  box-shadow: $light-box-shadow;
  margin-bottom: 8px;
  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
    background-color: $hover-bg;
  }

  .item-content-main {
    @include flex-col;
    padding: 16px;
    border-top-left-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
    width: 100%;
    height: 100%;
    overflow: hidden;


    .item-header {
      @include flex-row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      overflow: hidden;

      .item-header-txt {
        @include truncate-w-ellipsis;
        min-width: 0;
        flex: 1;

        h1 {
          @include txt-heading('sm');
          @include truncate-w-ellipsis;
        }
        h2 {
          @include txt-body('sm');
          @include txt-emphasis('medium');
          @include truncate-w-ellipsis;
        }
      }

      .item-header-pinned {
        .item-pinned-svg {
          transform: rotate(12deg);
          path {
            fill: $sky-blue-rgb;
          }
        }
      }
    }

    .item-divider {
      @include divider;
    }

    .item-body {
      @include flex-row;
      justify-content: flex-start;
      align-items: center;
      p {
        @include txt-body('sm');
      }
    }
  }

  .item-content-alerts {
    @include flex-col;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 10%;
    border-top-right-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;

    .alert-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        border-top-right-radius: $card-border-radius;
      }
      &:last-child {
        border-bottom-right-radius: $card-border-radius;
      }

      &.warning {
        background-color: $warning;
      }

      &.danger {
        background-color: $danger;
      }
    }
  }
}

// Compatible scrollbars
@mixin scrollbars {
  // Firefox scrollbars
  scrollbar-width: 16px;
  scrollbar-color: rgba(var(--ion-text-color-rgb), 0.2) transparent;

  // Chrome, Edge, Safari scrollbars
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--ion-text-color-rgb), 0.2);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(var(--ion-text-color-rgb), 0.3);
  }
}
