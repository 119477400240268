$border-radius: 6px;
$border-idle: 1px solid rgba(var(--ion-text-color-rgb), 0.3);
$border-hover: 1px solid rgba(var(--ion-text-color-rgb), 0.45);
$border-active: 1px solid rgba(var(--ion-text-color-rgb), 0.6);

$placeholder-txt-color: rgba(var(--ion-text-color-rgb), 0.6);
$hover-bg: rgba(var(--hover-background), 0.1);

// $shadow-inset-idle: inset 0px 0px 6px 1px rgba(var(--hover-background), 0.1);
$shadow-inset-idle: inset 0px 0px 6px 1px rgba(var(--ion-text-color-rgb), 0.1);
$shadow-inset-active: inset 0px 0px 6px 1px rgba(var(--ion-text-color-rgb), 0.2);
// $shadow-inset-active: inset 0px 0px 6px 1px rgba(var(--hover-background), 0.2);

// Form field input
@mixin form-field-input {
  // Can't user global variables here because we don't want the login form to use dynamic values
  $txt-color: rgba(0, 0, 0, 1);
  $txt-color-placeholder: rgba(0, 0, 0, 0.6);
  $border: 1px solid rgba(0, 0, 0, 0.3);
  $inset-shadow: inset 0px 0px 6px 1px rgba(0, 0, 0, 0.2);

  @include flex-row;
  @include screen-size-medium {
    margin-top: 8px;
  }
  align-items: center;
  min-height: 48px;
  width: 100%;
  border: $border;
  border-radius: $border-radius;
  padding: 0.25em 0.5em;
  margin-top: 16px;
  position: relative;

  font-size: 1rem;
  line-height: 1.5;
  color: $txt-color;

  background-color: rgba($foggy-gray-rgb, 0.1);
  transition: all 0.3s ease;

  &:focus-within {
    box-shadow: $inset-shadow;
  }

  &:hover {
    box-shadow: $inset-shadow;
  }

  label {
    @include flex-col;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 48px;
    color: $txt-color-placeholder;
  }

  input {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    margin: 0;
    font-family: inherit;
    background: none;

    margin-right: 48px;
    color: $txt-color;

    &::placeholder {
      color: $txt-color-placeholder;
    }

    &:valid + .check {
      transform: translate(0, 0) scale(1);
    }
  }

  .check {
    fill: none;
    position: absolute;
    right: 16px;
    transform: translate(0, 9px) scale(0);
    transition: all 0.3s cubic-bezier(0.5, 0.9, 0.25, 1.3);
    transition-delay: 0.15s;

    path {
      stroke: $field-green-deep-hex;
      stroke-width: 2;
    }
  }
}

// Search input
@mixin search-input {
  @include flex-row;
  align-items: center;
  position: relative;
  min-height: 48px;
  width: 100%;
  border: $border-idle;
  border-radius: $border-radius;
  padding: 0.25em 0.5em;

  font-size: 1rem;
  line-height: 1.5;
  color: var(--ion-text-color);

  background-color: rgba($foggy-gray-rgb, 0.1);
  transition: all 0.3s ease;

  &:focus-within {
    box-shadow: $shadow-inset-active;
  }

  &:hover {
    box-shadow: $shadow-inset-active;
  }

  label {
    @include flex-col;
    margin: 0;
    padding: 0;
    color: $placeholder-txt-color;
    width: 100%;
    max-width: 48px;
    justify-content: center;
    align-items: center;
  }

  input {
    height: 100%;
    border: 0;
    outline: 0;
    margin: 0px 32px 0px 0px;
    font-family: inherit;
    background: none;
    width: 100%;

    &::placeholder {
      color: $placeholder-txt-color;
    }

    &:valid + .clear-wrapper {
      transform: translate(0, 0) scale(1);
    }
  }

  // Clear button wrapper
  .clear-wrapper {
    @include flex-col;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 8px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;

    transform: translate(0, 9px) scale(0);
    transition: all 0.3s cubic-bezier(0.5, 0.9, 0.25, 1.3);

    &:hover {
      cursor: pointer;
      background-color: $hover-bg;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;

      path {
        stroke: $placeholder-txt-color;
      }
    }
  }
}

// Sort by selector
@mixin sort-select {
  $active-txt-color: rgba(var(--ion-text-color-rgb), 0.87);
  $option-txt-color: rgba(var(--ion-text-color-rgb), 0.6);

  $bg: var(--ion-item-background);

  position: relative;
  cursor: pointer;

  .select-btn {
    position: relative;
    display: block;
    border: $border-idle;
    border-radius: $border-radius;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25em 0.5em;
    height: 48px;
    min-width: 15ch;
    background: $bg;
    cursor: pointer;

    transition: all 0.3s ease;

    &:hover {
      background: $hover-bg;
      box-shadow: $shadow-inset-idle;
    }

    &.open {
      box-shadow: $shadow-inset-active;
    }

    label {
      font-size: 1rem;
      line-height: 1.5;
      cursor: pointer;

      &.placeholder {
        color: $placeholder-txt-color;
      }

      &.active {
        color: $active-txt-color;
      }
    }

    span {
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 8px;
        height: 2px;
        border-radius: 1px;
        top: 50%;
        right: 15px;
        background: $placeholder-txt-color;
        transition: all 0.3s ease;
      }

      &:before {
        margin-right: 4px;
        transform: scale(0.96, 0.8) rotate(50deg);
      }
      &:after {
        transform: scale(0.96, 0.8) rotate(-50deg);
      }

      &.open {
        &:before {
          transform: scale(0.96, 0.8) rotate(-50deg);
        }
        &:after {
          transform: scale(0.96, 0.8) rotate(50deg);
        }
      }
    }
  }

  .options {
    background: $bg;
    border-radius: $btn-border-radius;
    box-shadow: $smooth-box-shadow;
    padding: 0.5em 0em;
    min-width: 15ch;
    position: absolute;
    top: 100%;

    z-index: 0;
    opacity: 0;
    visibility: hidden;
    transform-origin: 0, 0;
    transition: opacity 0.2s ease, visibility 0.2s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
    transform: scale(0.8) translate(0, 4px);

    &.open {
      z-index: 1;
      opacity: 1;
      visibility: visible;
      transform: scale(1) translate(0, 12px);
      transition: opacity 0.3s ease, visibility 0.3s ease,
        transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);

      ul,
      li {
        opacity: 1;
        visibility: visible;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      opacity: 0;
      visibility: hidden;
    }

    li {
      color: $option-txt-color;
      font-size: 1rem;
      line-height: 1.5;
      padding: 0.75em;
      transition: all 0.3s ease;
      opacity: 0;
      visibility: hidden;

      &:hover {
        color: $active-txt-color;
      }
      &.active {
        color: $active-txt-color;
        background: $hover-bg;
        border-radius: $btn-border-radius;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bbb;
  background-color: #eee;
  border-radius: 6px;
  color: #aaa;
  margin: 0 10px 0 0;
  cursor: pointer;

  ion-icon {
    display: none;
    height: 16px;
    width: 16px;
    font-size: 16px;
    line-height: 16px;
  }

  &.checked {
    color: #000;

    ion-icon {
      display: inline;
    }
  }
}
