// Fade in
@mixin fade-in($speed: 1s) {
  animation: fadeIn $speed ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin slide-left($speed: 1s) {
  animation: slideLeft $speed ease;
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@mixin slide-right($speed: 1s) {
  animation: slideRight $speed ease;
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@mixin slide-up($speed: 1s) {
  animation: slideUp $speed ease;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}