// Importing our styles here, so that we only have to import this file at the top of scss files to access everything
@import "./colors.scss";
@import "./layout.scss";
@import "./animations.scss";
@import "./components.scss";
@import "./typography.scss";
@import "./inputs.scss";
@import "./buttons.scss";
@import "./pairing.scss";
@import "./drop-down.scss";

// Dark smooth box shadow
$smooth-box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);

// Lighter box shadow
$light-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

// Utilities
// Applies a randomly colored border to all child elements
@mixin all-the-borders {
  // Up to how many child elements to put borders on
  $upToCount: 15;

  @for $i from 1 through $upToCount {
    > * {
      &:nth-child(#{$i}) {
        border: 1px solid rgb(random(255), random(255), random(255));
      }
    }
  }
}

.popup-txt {
  @include txt-body('sm');
  @include txt-emphasis('medium');
}
