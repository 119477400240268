@mixin txt-emphasis($emphasis: 'high') {
  color: var(--ion-text-color);
  @if $emphasis == 'high' {
    opacity: 0.87;
  }
  @else if $emphasis == 'medium' {
    opacity: 0.6;
  }
  @else if $emphasis == 'disabled' {
    opacity: 0.38;
  }
}

// Heading text
@mixin txt-heading($size: 'md', $weight: normal, $lineHeight: 1.25) {
  margin: 0;
  @if $size == 'xl' {
    font-size: 2.441rem;
    font-weight: $weight;
    line-height: $lineHeight;
  }
  @else if $size == 'lg' {
    font-size: 1.953rem;
    font-weight: $weight;
    line-height: $lineHeight;
  }
  @else if $size == 'md' {
    font-size: 1.563rem;
    font-weight: $weight;
    line-height: $lineHeight;
  }
  @else if $size =='sm' {
    font-size: 1.25rem;
    font-weight: $weight;
    line-height: $lineHeight;
  }
}

// Body text
@mixin txt-body($size: 'md', $weight: normal, $lineHeight: 1.5) {
  margin: 0;
  @if $size == 'lg' {
    font-size: 1.25rem;
    font-weight: $weight;
    line-height: $lineHeight;
  }
  @else if $size == 'md' {
    font-size: 1rem;
    font-weight: $weight;
    line-height: $lineHeight;
  }
  @else if $size =='sm' {
    font-size: 0.8rem;
    font-weight: $weight;
    line-height: $lineHeight;
  }
  @else if $size =='x-sm' {
    font-size: 0.64rem;
    font-weight: $weight;
    line-height: $lineHeight;
  }
}

// Truncates text with ellipsis
@mixin truncate-w-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}