app-pairing-data,
app-modify-asset,
app-bulk-pair {
  ion-content {
    --overflow-x: hidden;
    --overflow-y: auto;
  }

  .temp {
    padding: 25px;

    .actions,
    .paging {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button,
      select {
        position: relative;
        display: flex;
        align-items: center;
        padding: 9px 15px;
        background-color: rgba(0, 0, 0, 0.1);
        font-size: 18px;
        border-radius: 5px;
        margin-top: 18px;
        border: none;
        outline: none;
        --webkit-display: none;
        --moz-display: none;
        cursor: pointer;

        &.page {
          background-color: transparent;
          padding: 0;
          margin: 0;
          font-size: 1rem;
          color: rgba(0, 0, 0, 0.7);
          cursor: pointer;
        }

        ion-icon {
          font-size: 20px;
          margin: 0 5px;
        }

        input[type="file"] {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          margin: 0;
          padding: 0;
          border: none;
          outline: none;
          background: none;
          --webkit-display: none;
          --moz-display: none;
          cursor: pointer;
        }
        option {
          position: relative;
          display: flex;
          align-items: center;
          padding: 4px 15px;
          font-size: 18px;
          border: none;
          outline: none;
          --webkit-display: none;
          --moz-display: none;
          cursor: pointer;
        }
      }

      .page {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
        padding: 4px;
      }

      .page + .page {
        margin-left: 1rem;
      }
    }
  }

  h1,
  h2 {
    font-weight: 600;
  }

  hr {
    height: 1px;
    margin: 2rem 0;
    border-bottom: 1px solid #bbb;
  }

  p {
    color: rgba(0, 0, 0, 0.7);
  }

  .table {
    position: relative;
    // max-height: 60vh;
    // width: 100%;
    // max-width: 100%;
    // overflow-x: auto;
    // overflow-y: hidden;

    &.data {
      max-height: i;
      overflow-x: initial;
      overflow-y: initial;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    padding: 0;

    &.temp-w {
      width: 90%;
    }

    &.striped {
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: rgba(0, 0, 0, 0.01);
          }
        }
      }
    }

    tr {
      border-bottom: 1px solid rgb(240, 239, 239);
      width: 100%;

      td {
        line-height: 28px;
        vertical-align: middle;
      }

      th,
      td {
        white-space: nowrap;
        text-align: left;
        padding: 1rem 1rem 1rem 0.25rem;

        &:first-of-type {
          padding-left: 1rem;
        }

        &.center {
          text-align: center;
        }

        &.nested {
          padding: 0;
        }

        .flex {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.grow {
          flex-grow: 1;
        }

        .checkbox {
          -webkit-appearance: none;
          -moz-appearance: none;
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #bbb;
          background-color: #eee;
          border-radius: 6px;
          color: #aaa;
          margin: 0 10px 0 0;
          cursor: pointer;

          ion-icon {
            display: none;
            height: 16px;
            width: 16px;
            font-size: 16px;
            line-height: 16px;
          }

          &.checked {
            color: #000;

            ion-icon {
              display: inline;
            }
          }
        }

        .clickable {
          cursor: pointer;
        }
      }

      th {
        font-weight: normal;
        color: #aaa;
      }
    }
  }
  .search {
    position: relative;
    border: 1px solid #bbb;
    border-radius: 5px;
    padding: 6px 24px 6px 12px;

    input {
      border: none;
      outline: none;
    }

    ion-icon {
      position: absolute;
      right: 5px;
      top: 8px;
    }
  }
}

input:not(.checkbox),
select:not(.org-select) {
  &.lg {
    min-width: 250px;
  }

  &.sm {
    min-width: 50px;
  }
}

input,
select:not(.org-select) {
  padding: 6px 24px 6px 12px;
  border-radius: 8px;
  margin: 0;
  font-size: 1rem;
  color: inherit;
  cursor: pointer;
  outline: none !important;
  width: 100%;
  border: 1px solid #f0efef;
  background-color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;
}

@supports (min-width: max-content) {
  input,
  select:not(.org-select) {
    &.lg {
      width: auto;
      min-width: 150px;
    }
  }
}

input:checked::after {
  content: "✓";
  font-size: 1.5rem;
  color: #000;
}

.inner-scroll {
  overflow: initial !important;
}
